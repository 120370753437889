<template>
    <div class="dataAnalysis-container">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb-student">
            <el-breadcrumb-item>数据分析</el-breadcrumb-item>
            <el-breadcrumb-item>数据管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="dataAnalysis-wrapper">
            <div class="tab-wrapper">
                <router-link to="/student/dataAnalysis/index" class="tab-item current">数据管理</router-link>
                <router-link to="/student/dataAnalysis/create" class="tab-item">创建数据</router-link>
            </div>
            <div class="batch-wrapper">
                <div class="batch-btn" @click="batchDelBtn">
                    <i class="el-icon-delete"></i>删除
                </div>
                <span class="choose-number">已选{{chooseNumber}}个</span>
            </div>
            <el-table :data="dataAnalysisList" style="width: 100%; margin-top: 10px; flex: 1" height="1%"
                      @selection-change="selectChange"
                      :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#EFEEFF', height: '50px'}"
                      :cell-style="{fontSize: '12px',color: '#333'}">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="数据名称" align="left">
                    <template slot-scope="scope">
                        <div class="text-overflow" :title="scope.row.title">{{scope.row.title}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-link :underline="false" class="link-blue" @click="seeBtn(scope.row.id)">查看</el-link>
                        <el-link :underline="false" class="link-blue" @click="editBtn(scope.row.id)">编辑</el-link>
                        <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pages-center" style="margin-bottom: 10px"
                           :current-page.sync="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="pagesCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataAnalysisList: [],
                selectArr: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                chooseNumber: 0,
            }
        },
        mounted() {
            this.getDataList()
        },
        methods: {
            // 获取数据列表
            getDataList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                this.$httpStudent.axiosGetBy(this.$api.analyse_analyseList, params, (res) => {
                    if (res.code === 200) {
                        this.dataAnalysisList = res.data.data
                        this.listPages.total = res.data.total
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getDataList()
            },
            // 选择删除list
            selectChange(selection) {
                this.selectArr = selection
                this.chooseNumber = selection.length
            },
            // 查看
            seeBtn(id) {
                this.$router.push({
                    path: '/student/dataAnalysis/detail',
                    query: {
                        id: id
                    }
                })
            },
            // 编辑
            editBtn(id) {
                this.$router.push({
                    path: '/student/dataAnalysis/create',
                    query: {
                        id: id
                    }
                })
            },
            // 删除
            delBtn(id) {
                this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'blue-btn',
                    type: 'warning',
                }).then(() => {
                    this.$httpStudent.axiosGetBy(this.$api.analyse_delAnalyses, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message)
                            this.getDataList()
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            // 批量删除
            batchDelBtn() {
                if (this.selectArr.length === 0) {
                    return this.$message.warning('请选择要删除的数据')
                }
                this.$confirm('是否删除选择的数据，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'blue-btn',
                    type: 'warning',
                }).then(() => {
                    let arr = this.selectArr.map(item => {
                        return item.id
                    })
                    this.$httpStudent.axiosGetBy(this.$api.analyse_delAnalyses, {id: arr.join(',')}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message)
                            this.getDataList()
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .dataAnalysis-container {
        position: relative;
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        .dataAnalysis-wrapper {
            display: flex;
            height: 1%;
            flex: 1;
            flex-direction: column;
            background: #fff;
            margin-top: 20px;
            border-radius: 10px;
            padding: 30px 40px 0;
            box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
            .tab-wrapper {
                .tab-item {
                    display: inline-block;
                    height: 32px;
                    padding: 0 22px;
                    line-height: 32px;
                    background: #F1F0FE;
                    color: #584EEE;
                    font-size: 16px;
                    margin-right: 20px;
                    border-radius: 4px;
                    transition: all .3s;
                    &:last-child {
                        margin-right: 0;
                    }
                    &.current, &:hover {
                        color: #fff;
                        background: #574DED;
                    }
                }
            }
            .batch-wrapper {
                margin-top: 30px;
                display: flex;
                .batch-btn {
                    cursor: pointer;
                    transition: all .3s;
                    i {
                        margin-right: 6px;
                    }
                    &:hover {
                        color: #5864e4;
                    }
                }
                .choose-number {
                    margin-left: 20px;
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
</style>